import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateSection {
  id?: number;
  name: string;
  report: {
    id?: number;
    name?: string;
  }[];

}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  reportData: any[]
}

const AddOrUpdateSectionModal = ({
  data,
  onSubmit,
  cancelModal,
  reportData
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    report: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    report: data.report
    ? reportData.find((x: any) => x.id === data.report)
    : reportData[0],
    userCreated: data.userCreated ? data.userCreated : "",


  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateSection>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {data.id ?  properties.com_parval_label_button_edit_section : properties.com_parval_label_button_add_section}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="report"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={ reportData || []}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id 
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Reporte"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.report}
                          helperText={errors.report && errors.report.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateSectionModal;
