import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { dataCommun } from "../../utils/DataCheckbox";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateAccount {
  id?: number;
  generalExpensesType?: string;
  statusType?: string;
  accountType?: string;
  statusResultLine?: string;
  account?: string;
  area?: {
    id?: number;
    name?: string;
  }[];
  office?: {
    id?: number;
    name?: string;
  }[];
  description: string;
  projectionType: {
    id?: string;
    name?: string;
  }[];
  generalExpenses: {
    id?: number;
    name?: string;
  }[];
  currency: {
    id?: string;
    name?: string;
  }[];
  level: {
    id?: number;
    name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  dataArea?: any;
  dataOffice?: any;
}


const AddOrUpdateAccountModal = ({
  data,
  onSubmit,
  cancelModal,
  dataArea,
  dataOffice,
}: DialogProps) => {


  //Validación de datos
  const validation = Yup.object().shape({
    description: Yup.string().required("Campo es requerido"),
    account: Yup.string().required("Campo es requerido"),
    statusResultLine: Yup.string().required("Campo es requerido"),
    statusType: Yup.string().required("Campo es requerido"),
    accountType: Yup.string().required("Campo es requerido"),
    generalExpensesType: Yup.string().required("Campo es requerido"),
    area: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    office: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    projectionType: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    generalExpenses: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    currency: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    level: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),

  });

  const dataCurrency = [
    {
      id: "USD",
      name: "Dólares",
    },
    {
      id: "DOP",
      name: "Pesos dominicanos",
    },
  ]

  const dataprojectionType = [
    {
      id: "H",
      name: "Histórica",
    },
    {
      id: "L",
      name: "Lineal",
    },
    {
      id: "F",
      name: "% Fijo",
    },
    {
      id: "P",
      name: "Proyección",
    }

  ]

  console.log(dataArea)

  console.log(data.area)

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : "",
    account: data.account ? data.account : "",
    statusResultLine: data.statusResultLine ? data.statusResultLine : "",
    statusType: data.statusType ? data.statusType : "",
    accountType: data.accountType ? data.accountType : "",
    generalExpensesType: data.generalExpensesType ? data.generalExpensesType : "",
    area: data.area ? dataArea.find((item: any) => item.id === Number(data.area)) : "",
    office: data.office ? dataOffice.find((item: any) => item.id === Number(data.office)) : "",
    currency: dataCurrency.find((item: any) => item.id === data.currency) ? dataCurrency.find((item: any) => item.id === data.currency) : "",
    projectionType: dataprojectionType.find((item: any) => item.id === data.projectionType) ? dataprojectionType.find((item: any) => item.id === data.projectionType) : "",
    level: dataCommun.find((item: any) => item.id === data.level) ? dataCommun.find((item: any) => item.id === data.level) : "",
    generalExpenses: dataCommun.find((item: any) => item.id === data.generalExpenses) ? dataCommun.find((item: any) => item.id === data.generalExpenses) : "",
    userCreated: data.userCreated ? data.userCreated : "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateAccount>({
    // @ts-ignore
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {data.id ? properties.com_parval_label_button_edit_account : properties.com_parval_label_button_add_account}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"account"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cuenta"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("account")}
                    error={errors.account && Boolean(errors.account)}
                    helperText={errors.account && errors.account.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={errors.description && errors.description.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"statusResultLine"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Línea de resultado de estado"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("statusResultLine")}
                    error={errors.statusResultLine && Boolean(errors.statusResultLine)}
                    helperText={errors.statusResultLine && errors.statusResultLine.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"statusType"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tipo de estado"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("statusType")}
                    error={errors.statusType && Boolean(errors.statusType)}
                    helperText={errors.statusType && errors.statusType.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"accountType"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tipo de cuenta"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("accountType")}
                    error={errors.accountType && Boolean(errors.accountType)}
                    helperText={errors.accountType && errors.accountType.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="currency"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataCurrency}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Moneda"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.currency}
                          helperText={errors.currency && errors.currency.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="projectionType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataprojectionType}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de proyección"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.projectionType}
                          helperText={errors.projectionType && errors.projectionType.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="generalExpenses"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataCommun}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Gastos generales"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.generalExpenses}
                          helperText={errors.generalExpenses && errors.generalExpenses.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"generalExpensesType"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tipo de gastos generales"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("generalExpensesType")}
                    error={errors.generalExpensesType && Boolean(errors.generalExpensesType)}
                    helperText={errors.generalExpensesType && errors.generalExpensesType.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="level"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataCommun}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nivel"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.level}
                          helperText={errors.level && errors.level.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Controller
                name={"area"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Area"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("area")}
                    error={errors.area && Boolean(errors.area)}
                    helperText={errors.area && errors.area.message}
                  />
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Controller
                name={"office"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Oficina"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("office")}
                    error={errors.office && Boolean(errors.office)}
                    helperText={errors.office && errors.office.message}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Controller
                control={control}
                name="area"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataArea || []}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Area"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.area}
                          helperText={errors.area && errors.area.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="office"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataOffice}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Oficina"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.office}
                          helperText={errors.office && errors.office.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateAccountModal;
