import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import Loading from '../components/utils/Loading';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Typography } from '@mui/material';
import { getReports } from '../services/report/ReportService';
import { generateReport } from '../services/generateReport/GenerateReportService';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const Report = () => {
  const {
    isLoading,
    setLoading,
    setErrorMsg,
  } = useApp();
  const [reportsData, setReportsData] = useState<any>({});
  const { reportId } = useParams<{ reportId: string }>();

  const { handleSubmit, control, setValue, watch } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    setLoading && setLoading(true);
    const dataInit = async () => {
      await handleFetchData(0, reportId);

    };

    dataInit();





    // eslint-disable-next-line
  }, [reportId]);

  const handleFetchData = async (currentPage: number, filtro?: string) => {
    setLoading && setLoading(true);
    setValue('startDate', moment().format('YYYY-MM'));
    try {

      let data = await getReports(currentPage, 1, filtro);

      if (data) {
        setReportsData(data.page.content[0]);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const onSubmit = async (data: any) => {

    try {
      setLoading && setLoading(true);
      const formattedStartDate = moment(data.startDate).format('MM/YYYY');
      const formattedEndDate = reportsData && reportsData.typeFilter === "anual" ? moment(data.endDate).format('MM/YYYY') : ""
      const response = await generateReport(Number(reportId), formattedStartDate, formattedEndDate, data.accumulated);
      const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `${reportsData && reportsData.name} ${formattedStartDate} - ${formattedEndDate}.xlsx`;
      downloadLink.click();



    } catch (error: any) {
      setErrorMsg && setErrorMsg(reportsData.typeFilter === 'anual' ? 'Para el rango seleccionado no existe data' : 'Para el mes seleccionado no existe data');
    } finally {
      setLoading && setLoading(false);
    }
  };

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');

  useEffect(() => {

    if (watchStartDate && watchEndDate && moment(watchStartDate).isAfter(watchEndDate)) {
      setValue('endDate', watchStartDate);
    }
  }
    , [watchStartDate, watchEndDate, setValue]);

  return (
    <>
      {isLoading ? (
        <Loading />

      )
        : (
          <FancyPaper pagetitle='Descarga de Reportes'>

            <Typography>
              Descarga de reportes de {reportsData && reportsData.name}
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <Box display="flex" flexDirection="column" marginTop="3rem" justifyContent="center"> */}

              <Grid container gap={3} marginY="2rem" display="flex" alignItems="center" justifyContent="center">

                {window.navigator.userAgent.includes("Firefox")
                  ? (


                    <Grid item xs={12} lg={2}>
                      <Box>
                      <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                          {reportsData && reportsData.typeFilter === 'anual' ? 'Fecha Inicial' : 'Fecha'}
                        </Typography>
                      </Box>
                      <Controller
                        name={'startDate'}
                        control={control}

                        defaultValue={moment().format('YYYY-MM')}
                        render={({ field: { onChange, value } }) => {
                          const year = moment(value).format('YYYY');
                          const month = moment(value).format('MM');

                          const handleYearChange = (newYear: any) => {
                            onChange(`${newYear}-${month}`);
                          };

                          const handleMonthChange = (newMonth: any) => {
                            onChange(`${year}-${newMonth}`);
                          };

                          return (
                            <Box display="flex">

                              <Select
                                fullWidth
                                onChange={(e) => handleMonthChange(e.target.value)}
                                size='small'
                                variant='standard'
                                value={month}
                              >
                                {Array.from({ length: 12 }, (_, i) => (
                                  <MenuItem key={i} value={String(i + 1).padStart(2, '0')}>
                                    {monthNames[i]}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Select

                                fullWidth
                                onChange={(e) => handleYearChange(e.target.value)}
                                size='small'
                                label={reportsData && reportsData.typeFilter === 'anual' ? 'Fecha Inicial' : 'Fecha'}
                                variant='standard'
                                value={year}
                              >
                                {Array.from({ length: 10 }, (_, i) => (
                                  <MenuItem key={i} value={moment().subtract(i, 'years').format('YYYY')}>
                                    {moment().subtract(i, 'years').format('YYYY')}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          );
                        }}
                      />
                    </Grid>
                  ) :
                  (
                    <Grid item xs={12} lg={2}>
                      <Controller
                        name={'startDate'}
                        control={control}
                        defaultValue={moment().format('YYYY-MM')}

                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            inputProps={{
                              max: moment().format('YYYY-MM'),
                            }}
                            size='small'
                            label={reportsData && reportsData.typeFilter === 'anual' ? 'Fecha Inicial' : 'Fecha'}
                            type='month'
                            sx={{
                              '& input': {
                                pl: '0!important'
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='standard'
                            value={value}


                          />
                        )}
                      />
                    </Grid>
                  )
                }

                {reportsData && reportsData.typeFilter === "anual" && (

                  window.navigator.userAgent.includes("Firefox") ? (
                    <Grid item xs={12} lg={2}>
                      <Box>
                      <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                          Fecha Final
                        </Typography>
                      </Box>
                      <Controller
                        name={'endDate'}
                        control={control}

                        defaultValue={moment().format('YYYY-MM')}
                        render={({ field: { onChange, value } }) => {
                          const year = moment(value).format('YYYY');
                          const month = moment(value).format('MM');

                          const handleYearChange = (newYear: any) => {
                            onChange(`${newYear}-${month}`);
                          };

                          const handleMonthChange = (newMonth: any) => {
                            onChange(`${year}-${newMonth}`);
                          };

                          return (
                            <Box display="flex">
                               <Select
                                fullWidth
                                onChange={(e) => handleMonthChange(e.target.value)}
                                size='small'
                                variant='standard'
                                value={month}
                              >
                                {Array.from({ length: 12 }, (_, i) => (
                                  <MenuItem key={i} value={String(i + 1).padStart(2, '0')}>
                                    {monthNames[i]}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Select

                                fullWidth
                                onChange={(e) => handleYearChange(e.target.value)}
                                size='small'
                                label={reportsData && reportsData.typeFilter === 'anual' ? 'Fecha Inicial' : 'Fecha'}
                                variant='standard'
                                value={year}
                              >
                                {Array.from({ length: 10 }, (_, i) => (
                                  <MenuItem key={i} value={moment().subtract(i, 'years').format('YYYY')}>
                                    {moment().subtract(i, 'years').format('YYYY')}
                                  </MenuItem>
                                ))}
                              </Select>
                             
                            </Box>
                          );
                        }}
                      />
                    </Grid>
                  ) : (




                    <Grid item xs={12} lg={2}>
                      <Controller
                        name={'endDate'}
                        control={control}
                        defaultValue={moment().format('YYYY-MM')}

                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            onChange={onChange}
                            inputProps={{
                              max: moment().format('YYYY-MM'),
                            }}
                            size='small'
                            label='Fecha Final'
                            type='month'
                            sx={{
                              '& input': {
                                pl: '0!important'
                              }
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant='standard'
                            value={value}


                          />
                        )}
                      />
                    </Grid>
                  )

                )}
              </Grid>



              <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
                {/* <Controller
                  control={control}
                  defaultValue={false}
                  name="accumulated"
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            marginLeft: '10px',
                          }}
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                        />
                      }
                      label="Acumulado"
                    />
                  )}
                /> */}
                <Button type="submit" variant="contained" sx={{
                  maxHeight: '2.5rem',
                  marginX: "auto"
                }}>
                  Descargar Reporte
                </Button>
              </Box>

              {/* </Box> */}

            </form>


          </FancyPaper>


        )
      }
    </>
  );
};

export default Report;
