import { properties } from '../../utils/Properties_es';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ContentOffice, ModalOffice } from '../../types/Office';


const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdateOffice {
  id?: number;
  name: string;
  description: string;
  codeEntity: string;
  codeAgency: string;
}

interface DialogProps {
  actionButton?: any;
  data: ContentOffice;
  onSubmit: (data: ModalOffice) => Promise<void>
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateOfficeModal = ({
  data,
  onSubmit,
  cancelModal,
  // areaData,
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required('Campo es requerido'),
    description: Yup.string().required('Campo es requerido'),
    codeEntity: Yup.string().required('Campo es requerido'),
    codeAgency: Yup.string().required('Campo es requerido'),

  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : '',
    name: data.name ? data.name : '',
    codeEntity: data.codeEntity ? data.codeEntity : '',
    codeAgency: data.codeAgency ? data.codeAgency : '',
    userCreated: data.userCreated ? data.userCreated : '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, },
    control,
  } = useForm<AddOrUpdateOffice>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });

  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {properties.com_parval_label_button_add_office}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Nombre'
                    type='text'
                    variant='standard'
                    inputProps={{ maxLength: 50 }}
                    value={value}
                    autoComplete='name'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('name')}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'description'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Descripción'
                    type='text'
                    inputProps={{ maxLength: 50 }}
                    variant='standard'
                    value={value}
                    autoComplete='description'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('description')}
                    error={errors.description && Boolean(errors.description)}
                    helperText={errors.description && errors.description.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'codeEntity'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Código de Entidad'
                    type='text'
                    variant='standard'
                    value={value}
                    inputProps={{ maxLength: 10 }}
                    autoComplete='codeEntity'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('codeEntity')}
                    error={errors.codeEntity && Boolean(errors.codeEntity)}
                    helperText={errors.codeEntity && errors.codeEntity.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'codeAgency'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Código de Agencia'
                    type='text'
                    variant='standard'
                    inputProps={{ maxLength: 10 }}
                    value={value}
                    autoComplete='codeAgency'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('codeAgency')}
                    error={errors.codeAgency && Boolean(errors.codeAgency)}
                    helperText={errors.codeAgency && errors.codeAgency.message}
                  />
                )}
              />
            </Grid>


            {/* <Grid item xs={12}>
              <Controller
                control={control}
                name="area"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={areaData || []}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Area"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.area}
                          helperText={errors.area && errors.area.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateOfficeModal;
