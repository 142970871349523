import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import Loading from '../components/utils/Loading';
import { SectionResponse } from '../types/Section';
import { getBalance } from '../services/balance/BalanceService';
import moment from 'moment';

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const defaultValues = {
  search: '',
  startDate: '',
  endDate: '',
};

const BalanceManagement = () => {

  const {
    isLoading,
    setLoading,
    setErrorMsg,
  } = useApp();
  const [sectionData, setSectionData] = useState<SectionResponse>({} as SectionResponse);
  const [page, setPage] = useState<number>(0);
  const [preFilter, setPreFilter] = useState(defaultValues);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const { handleSubmit, control, setValue, watch } = useForm();

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filtro?: string, startDate?: string, endDate?: string) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage || 0);

      let data = await getBalance(currentPage, 10, filtro, startDate, endDate);

      if (data) {
        setSectionData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };


  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter.search);
    }
  };

  const handleFilter = async (e: any) => {

    const filtro = e.search
    const startDate = e.startDate ? moment(e.startDate).format('MM/YYYY') : ''
    const endDate = e.endDate ? moment(e.endDate).format('MM/YYYY') : ''

    console.log(startDate, endDate)

    setPreFilter({
      search: filtro,
      startDate: startDate,
      endDate: endDate,

    })
    await handleFetchData(0, filtro, startDate, endDate);
  };

  const watchSearch = watch('search');
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  console.log(watchStartDate)


  const handleClearFilter = async (e: any) => {

    await handleFetchData(0);
    setPreFilter({
      search: '',
      startDate: '',
      endDate: '',
    })
    window.navigator.userAgent.includes('Chrome') ? setValue('startDate', '') : setValue('startDate', moment().format('YYYY-MM'));
    window.navigator.userAgent.includes('Chrome') ? setValue('endDate', '') : setValue('endDate', moment().format('YYYY-MM'));
    setValue('search', '');
  };

  const disableButton = () => {
    if ((watchStartDate && watchEndDate) || (watchSearch && ((watchStartDate && watchEndDate) || (!watchStartDate && !watchEndDate)))) {
      return false
    }
    else {
      return true
    }
  }

  return (
    <>
      {isLoading && (
        <Loading />
      )}
      <FancyPaper pagetitle='Balances'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12} display="flex" flexDirection="row" gap={6} >
            {window.navigator.userAgent.includes('Chrome') ? (
              <>
                <Controller
                  name={'startDate'}
                  control={control}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      onChange={onChange}
                      inputProps={{
                        max: moment().format('YYYY/MM'),
                      }}
                      size='small'
                      label={'Fecha Inicial'}
                      type='month'
                      sx={{
                        width: "20%",
                        '& input': {
                          pl: '0!important'
                        }
                      }}
                      // shrink
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // disabled={watchLeasing}
                      variant='standard'
                      value={value}

                    />
                  )}
                />

                <Controller
                  name={'endDate'}
                  control={control}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      onChange={onChange}
                      inputProps={{
                        max: moment().format('YYYY/MM'),
                      }}
                      size='small'
                      label='Fecha Final'
                      type='month'
                      sx={{
                        width: "20%",
                        '& input': {
                          pl: '0!important'
                        }
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant='standard'
                      value={value}


                    />
                  )}
                />
              </>

            ) : (

              <>
                <Grid item xs={12} lg={2}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                      Fecha Inicial
                    </Typography>
                  </Box>
                  <Controller
                    name={'startDate'}
                    control={control}

                    defaultValue={moment().format('YYYY-MM')}
                    render={({ field: { onChange, value } }) => {
                      const year = moment(value).format('YYYY');
                      const month = moment(value).format('MM');

                      const handleYearChange = (newYear: any) => {
                        onChange(`${newYear}-${month}`);
                      };

                      const handleMonthChange = (newMonth: any) => {
                        onChange(`${year}-${newMonth}`);
                      };

                      return (
                        <Box display="flex">
                          <Select
                            fullWidth
                            onChange={(e) => handleMonthChange(e.target.value)}
                            size='small'
                            variant='standard'
                            value={month}
                          >
                            {Array.from({ length: 12 }, (_, i) => (
                              <MenuItem key={i} value={String(i + 1).padStart(2, '0')}>
                                {monthNames[i]}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select

                            fullWidth
                            onChange={(e) => handleYearChange(e.target.value)}
                            size='small'
                            label='Fecha Inicial'
                            variant='standard'
                            value={year}
                          >
                            {Array.from({ length: 10 }, (_, i) => (
                              <MenuItem key={i} value={moment().subtract(i, 'years').format('YYYY')}>
                                {moment().subtract(i, 'years').format('YYYY')}
                              </MenuItem>
                            ))}
                          </Select>

                        </Box>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Box>
                    <Typography variant="subtitle1" sx={{ fontSize: "0.75rem" }}>
                      Fecha Final
                    </Typography>
                  </Box>
                  <Controller
                    name={'endDate'}
                    control={control}

                    defaultValue={moment().format('YYYY-MM')}
                    render={({ field: { onChange, value } }) => {
                      const year = moment(value).format('YYYY');
                      const month = moment(value).format('MM');

                      const handleYearChange = (newYear: any) => {
                        onChange(`${newYear}-${month}`);
                      };

                      const handleMonthChange = (newMonth: any) => {
                        onChange(`${year}-${newMonth}`);
                      };

                      return (
                        <Box display="flex">

                          <Select
                            fullWidth
                            onChange={(e) => handleMonthChange(e.target.value)}
                            size='small'
                            variant='standard'
                            value={month}
                          >
                            {Array.from({ length: 12 }, (_, i) => (
                              <MenuItem key={i} value={String(i + 1).padStart(2, '0')}>
                                {monthNames[i]}
                              </MenuItem>
                            ))}
                          </Select>
                          <Select

                            fullWidth
                            onChange={(e) => handleYearChange(e.target.value)}
                            size='small'
                            label='Fecha Final'
                            variant='standard'
                            value={year}
                          >
                            {Array.from({ length: 10 }, (_, i) => (
                              <MenuItem key={i} value={moment().subtract(i, 'years').format('YYYY')}>
                                {moment().subtract(i, 'years').format('YYYY')}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      );
                    }}
                  />
                </Grid>


              </>

            )}

            <Box display="flex" gap={4} justifyContent="flex-end" alignItems="center">
              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      width: '100%',
                      '& label': {
                        marginTop: '-5px'
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={disableButton()}
              >
                Filtrar
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Box>
            {/* <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit(handleFilterDate)}
              // disabled={watchSearch === '' ? true : false}
              sx={{
                height: "32px",
              }}
            >
              Filtrar por fecha
            </Button> */}
          </Grid>
          <Grid item md={3} sm={6} xs={12} >
            {/* <Box display="flex" gap={4} justifyContent="flex-end" alignItems="center">
              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      width: '100%',
                      '& label': {
                        marginTop: '-5px'
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={watchSearch === '' ? true : false}
              >
                Filtrar
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Box> */}


          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell>Fecha</TableCell>
                <TableCell align='center'>Moneda</TableCell>
                <TableCell align='center'>Cuenta</TableCell>
                <TableCell align='center'>Descripción</TableCell>
                <TableCell align='center'>Saldo anterior</TableCell>
                <TableCell align='center'>Debito</TableCell>
                <TableCell align='center'>Credito</TableCell>
                <TableCell align='center'>Saldo actual</TableCell>
                {/* <TableCell align='center'>Nivel</TableCell> */}
                <TableCell align='center'>Tipo de Estado</TableCell>
                {/* <TableCell align='center'>Tipo de Cuenta</TableCell> */}
                <TableCell align='center'>Tipo de Partida</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectionData.content &&
                sectionData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    {/* <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell> */}
                    <TableCell component='th' scope='row'>
                      {row.date}
                    </TableCell>
                    <TableCell align='center'>{row.currency}</TableCell>
                    <TableCell align='center'>{row.account}</TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    <TableCell align='center'>{row.previousBalance}</TableCell>
                    <TableCell align='center'>{row.debt}</TableCell>
                    <TableCell align='center'>{row.credit}</TableCell>
                    <TableCell align='center'>{row.actualBalance}</TableCell>
                    <TableCell align='center'>{row.statusType}</TableCell>
                    {/* <TableCell align='center'>{row.accountType}</TableCell> */}
                    <TableCell align='center'>{row.statusResultLine}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={sectionData?.content?.length > 0 ? sectionData?.content?.length : 0}
          rowsPerPage={sectionData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={sectionData.totalElements}
          totalPages={sectionData.totalPages}
        />
      </FancyPaper>
    </>
  );
};

export default BalanceManagement;
