import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { AxiosResponse } from "axios";
import { UrlBase } from "../../url/Urls";
import { properties } from "../../utils/Properties_es";

export const uploadFileProjected = async (data: FormData, userCreated: string, remove: boolean) => {

  try {
    const response = await privateFetch.post(
      URL.REQUEST_PROJECTED_UPLOAD_FILE(userCreated, remove),
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export const getProjected = async (page: number, size?: number, filtro?: string) => {

  try {
    const response: AxiosResponse = await privateFetch.get(
      URL.REQUEST_PROJECTED_LIST(
        page,
        size || UrlBase.registration_x_page
      ), {
      params: {
        filter: filtro || "",
      }
    }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_balance_find_error);

  }
};
