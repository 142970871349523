import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AreaResponse, BodyArea, ContentArea } from "../../types/Area";
import { AxiosResponse } from "axios";

export const getAreas = async (page: number, size?: number, filtro?: string): Promise<AreaResponse> => {

  try {
    const response: AxiosResponse<AreaResponse> = await privateFetch.get(
      URL.REQUEST_AREA_LIST(
        page,
        size || UrlBase.registration_x_page
      ),{
        params: {
          filter: filtro || ""
        }
      }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_area_find_error);

  }
};

export const createArea = async (data: BodyArea): Promise<ContentArea> => {
  try {
    const response: AxiosResponse<ContentArea> = await privateFetch.post(URL.REQUEST_AREA_CREATE, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_area_save_error);
  }
};

export const updateArea = async (data: BodyArea): Promise<ContentArea> => {
  try {
    const response = await privateFetch.put( URL.REQUEST_AREA_UPDATE_DELETE(data.id), data,
    
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_area_update_error);
  }
};


export const deleteArea = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_AREA_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_area_delete_error);
  }
};

