import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyReport, ContentReport, ReportResponse } from "../../types/Report";

export const getReports = async (page: number, size?: number, filtro?: string): Promise<ReportResponse> => {

  try {
    const response: AxiosResponse<ReportResponse> = await privateFetch.get(
      URL.REQUEST_REPORT_LIST(
        page,
        size || UrlBase.registration_x_page
      ),
      {
        params: {
          filter: filtro || "",
          sort: "position,asc",
        }
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error)
    throw new Error(error.response.data.code);

  }
};

export const createReport = async (data: BodyReport): Promise<ContentReport> => {
  try {
    const response: AxiosResponse<ContentReport> = await privateFetch.post(URL.REQUEST_REPORT_CREATE, {
      ...data
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    // throw new Error(properties.com_parval_label_office_save_error);
    throw new Error(error.response.data.code);
  }
};

export const updateReport = async (data: BodyReport): Promise<ContentReport> => {
  try {

    const response = await privateFetch.put(
      URL.REQUEST_REPORT_UPDATE_DELETE(data.id),
      JSON.stringify(data),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    // throw new Error(properties.com_parval_label_office_update_error);
    throw new Error(error.response.data.code);
  }
};


export const deleteReport = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_REPORT_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.code);
  }
};

