import { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Divider,
  Dialog,
} from "@mui/material";
import { TrashIcon, PlusIcon, PenIcon } from "../components/svgicons/SvgIcons";
import FancyPaper from "../components/FancyPaper";
import FancyTooltip from "../components/utils/FancyTooltip";
import FancyTablePagination from "../components/utils/FancyTablePagination";
import { useForm } from "react-hook-form";
import {
  getRequest,
  findByIdRequest,
  createRequest,
  updateRequest,
  deleteRequest,
} from "../services/authority/AuthorityService";
import { useApp } from "../hooks/useApp";
import { properties } from "../utils/Properties_es";
import AddOrUpdateAuthority from "../components/utils/AddOrUpdateAuthority";
import DeleteModal from "../components/utils/DeleteModal";
import ResourceAccess from "../components/security/ResourceAccess";
import Loading from "../components/utils/Loading";

const AuthorityManagement = () => {
  const {
    isLoading,
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [preFilter, setPreFilter] = useState<any>("");
  const [page, setPage] = useState(0);
  const [authorityData, setAuthorityData] = useState<any>(null);

  const initForm = {
    id: 0,
    code: "",
    createdBy: "",
    description: "",
    path: 0,
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData("", 0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const handleFetchData = async (filter: string, currentPage: number) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequest(filter, currentPage);
      if (data) {
        setAuthorityData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let authorityDataID: any = await findByIdRequest(id);
      if (authorityDataID) {
        setFormData(authorityDataID);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        createdBy: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg("Error en proceso de guardar permiso");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_save);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        //createdBy: authInfo?.username,
        modifiedBy: authInfo?.username,
      });
      console.log(data);

      if (!updateData) {
        setErrorMsg && setErrorMsg("Error en proceso de actualizar permiso");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_update);

      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (id: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRequest(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg("Error en proceso de eliminar permiso");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_request_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData("", 0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(preFilter, customPage);
    }
  };

  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(preFilter, 0);
    }
  };

  /** Validate errors in case of opening modal **/
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      await handleFetchByID(id);
    }

    if (modalAction === "delete") {
      object = authorityData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
  };

  return (
    <>
      {isLoading && (
        <Loading />
      )}
      <FancyPaper pagetitle="Permisos">
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={"AUTHORITY:WRITE"}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar permiso
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData("", 0);
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                  padding: "1.5px 16px!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Divider />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Url</TableCell>
                <TableCell >Descripción</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {authorityData &&
                authorityData.content &&
                authorityData.content.map((r: any) => (
                  <TableRow key={r.id}>
                    <TableCell component="th" scope="row">
                      {r.id}
                    </TableCell>
                    <TableCell>{r.code}</TableCell>
                    <TableCell>{r.path}</TableCell>
                    <TableCell>{r.description}</TableCell>
                    <TableCell align="center">
                      <ResourceAccess
                        isCode={true}
                        pathOrCode={"AUTHORITY:WRITE"}
                      >
                        <FancyTooltip title="Editar" placement="top">
                          <IconButton
                            aria-label="edit"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name="update"
                            data-id={r.id}
                          >
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title="Eliminar" placement="top">
                          <IconButton
                            aria-label="trash"
                            component="label"
                            color="secondary"
                            sx={{
                              "&:hover": {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={r.id}
                            onClick={handleOpenModal}
                            data-name="delete"
                          >
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={
            authorityData?.content?.length > 0
              ? authorityData?.content?.length
              : 0
          }
          rowsPerPage={authorityData?.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={authorityData?.totalElements}
          totalPages={authorityData?.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
          <Dialog
            open={modalData.modalOpen}
            onClose={handleCancelModal}
            fullWidth
          >
            <AddOrUpdateAuthority
              data={formData}
              onSubmit={onSubmit}
              cancelModal={handleCancelModal}
              modalType={modalData?.modalType}
            />
          </Dialog>
        )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.code}
            actionButton={
              <>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  Aceptar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus
                >
                  Cancelar
                </Button>{" "}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default AuthorityManagement;
