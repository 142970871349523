import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
  Box,
  TextField,
} from '@mui/material';
import { TrashIcon, PlusIcon, PenIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';

import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import DeleteModal from '../components/utils/DeleteModal';
import ResourceAccess from '../components/security/ResourceAccess';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import Loading from '../components/utils/Loading';
import { getReports } from '../services/report/ReportService';
import {
  createSection,
  deleteSection,
  getSection,
  updateSection,
} from '../services/section/SectionService';
import { BodySection, ContentSection, SectionResponse } from '../types/Section';
import AddOrUpdateSectionModal from '../components/utils/AddOrUpdateSection';

const initForm = {
  id: 0,
  name: '',
  report: {
    id: 0,
    name: '',
  },
};
type FormData = typeof initForm;

const defaultValues = {
  search: '',
};

const SectionManagement = () => {
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [sectionData, setSectionData] = useState<SectionResponse>({} as SectionResponse);
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>(initForm);
  const [allReports, setAllReports] = useState<any[]>([]);
  const [preFilter, setPreFilter] = useState(defaultValues);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const { handleSubmit, control, setValue, watch } = useForm();

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filtro?: string) => {
    setLoading && setLoading(true);
    try {
      let reportData = await getReports(0, 1000);
      if (reportData) {
        setAllReports(reportData.page.content);
      }

      setPage(currentPage || 0);

      let data = await getSection(currentPage, 10, filtro);

      if (data) {
        setSectionData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: BodySection) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createSection({
        ...data,
        report: data.report,
        userCreated: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  const getNameReport = (id: number) => {
    let report = allReports.find(p => p.id === id);
    return report?.name;
  };
  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: FormData): Promise<void> => {
    handleCancelModal();
    setLoading && setLoading(true);

    try {
      const updateData = await updateSection({
        ...data,
        // @ts-ignore
        report: Number(data.report.id),
        userModified: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: number) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteSection(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData(0);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */

  type ModalAction = 'update' | 'delete';
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name') as ModalAction;
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update') {
      object = sectionData.content.find((p: any) => p.id === parseInt(id));
      //@ts-ignore
      setFormData(object);
    }

    if (modalAction === 'delete') {
      object = sectionData.content.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        // @ts-ignore
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'delete':
        // @ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter.search);
    }
  };

  const handleFilter = async (e: any) => {
    const filtro = e.search;
    setPreFilter({
      search: filtro,
    });

    if (filtro === '') {
      await handleFetchData(0);
    } else {
      await handleFetchData(0, filtro);
    }
  };

  const watchSearch = watch('search');

  const handleClearFilter = async (e: any) => {
    await handleFetchData(0);
    setPreFilter({
      search: '',
    });
    setValue('search', '');
  };

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle='Secciones'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={8} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'SECTION:WRITE'}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleOpenModal}
                data-name='create'>
                Agregar Sección
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Box display='flex' gap={4} justifyContent='flex-end'>
              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      '& label': {
                        marginTop: '-5px',
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={watchSearch === '' ? true : false}>
                Buscar
              </Button>
              <Button variant='contained' color='primary' onClick={handleClearFilter}>
                Limpiar
              </Button>
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                {/* <TableCell align='center'>Descripción</TableCell> */}
                <TableCell align='center'>Reporte</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectionData.content &&
                sectionData.content.map((row: ContentSection, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align='center'>{getNameReport(row.report)}</TableCell>
                    <ResourceAccess isCode={true} pathOrCode={'SECTION:WRITE'}>
                      <TableCell align='center'>
                        <FancyTooltip title='Editar' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title='Eliminar' placement='top'>
                          <IconButton
                            aria-label='trash'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='delete'>
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                      </TableCell>
                    </ResourceAccess>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={sectionData?.content?.length > 0 ? sectionData?.content?.length : 0}
          rowsPerPage={sectionData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={sectionData.totalElements}
          totalPages={sectionData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateSectionModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            reportData={allReports}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default SectionManagement;
