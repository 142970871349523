import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
} from '@mui/material';
import { TrashIcon, PlusIcon, PenIcon, ConfigIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import { useForm } from 'react-hook-form';
import {
  getRoles,
  updateRole,
  findById,
  deleteRole,
  createRole,
} from '../services/role/RoleService';
import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import AddOrUpdateRoleModal from '../components/utils/AddOrUpdateRoleModal';
import DeleteModal from '../components/utils/DeleteModal';
import ResourceAccess from '../components/security/ResourceAccess';
import { Link } from 'react-router-dom';
import Loading from '../components/utils/Loading';

const RoleManagement = () => {
  const {
    isLoading,
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [roleData, setRoleData] = useState<any>([]);
  const initForm = {
    id: 0,
    adminLogin: true,
    clientLogin: false,
    description: '',
    name: '',
  };
  const [formData, setFormData] = useState<any>(initForm);
  const { handleSubmit } = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData();
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async () => {
    setLoading && setLoading(true);
    try {

      let data = await getRoles();
      if (data) {
        // @ts-ignore
        setRoleData(data.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);
    try {
      let roleData = await findById(id);
      // @ts-ignore
      if (roleData && roleData.content.length > 0) {
        // @ts-ignore
        const filterData = roleData.content.find((p: any) => p.id === parseInt(id));
        setFormData(filterData);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRole({
        ...data,
        userCreated: authInfo?.name,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        console.log('no se envia');
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      console.log('se envio');
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(data)
    try {
      let updateData = await updateRole({
        ...data,
        userModified: authInfo?.name,
        userCreated: formData?.userCreated,
        adminLogin: true

      });

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: number) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteRole(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update') {
      await handleFetchByID(id);
    }

    if (modalAction === 'delete') {
      object = roleData.find((p: any) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && (
        <Loading />
      )}
      <FancyPaper pagetitle='Perfiles'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'ROLE:WRITE'}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenModal}
              data-name='create'>
              Agregar Perfil
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            </ResourceAccess>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell align='center'>Descripción</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roleData &&
                roleData.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    {/* <TableCell align='center'>{row.adminLogin ? `Sí` : `No`}</TableCell> */}
                    {/* <TableCell align='center'>{row.clientLogin ? `Sí` : `No`}</TableCell> */}
                    <TableCell align='center'>
                      <ResourceAccess isCode={true} pathOrCode={'ROLE:WRITE'}>
                      <FancyTooltip title='Editar' placement='top'>
                        <IconButton
                          aria-label='edit'
                          component='label'
                          color='secondary'
                          sx={{
                            '&:hover': {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          onClick={handleOpenModal}
                          data-name='update'
                          data-id={row.id}>
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title='Permisos por perfil' placement='top'>
                        <Link to={'/authoritiesbyrole/' + row.id}>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-name='update'
                            data-id={row.id}>
                            <ConfigIcon />
                          </IconButton>
                        </Link>
                      </FancyTooltip>
                      <FancyTooltip title='Eliminar' placement='top'>
                        <IconButton
                          aria-label='trash'
                          component='label'
                          color='secondary'
                          sx={{
                            '&:hover': {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          data-id={row.id}
                          onClick={handleOpenModal}
                          data-name='delete'>
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateRoleModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default RoleManagement;
