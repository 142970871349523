import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";

export const getRoles = async () => {
  let responseData = {};  
  await privateFetch
    .get(
      URL.REQUEST_ROLE(null)
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      //throw new Error(properties.com_parval_label_role_find_error);
      throw new Error(error);
    });
  return responseData;
};

export const findById = async (id:any) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ROLE_ID(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createRole = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(URL.REQUEST_ROLE_CREATE, {
      ...data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_save_error);
    });
  return responseData;
};




export const updateRole = async (data:any) => {
  let responseData = {};  
  // const requestBody = {
  //   id: data.id,
  //   name: data.name,
  //   description: data.description,
  //   adminLogin: data.adminLogin,
  //   clientLogin:data.clientLogin,
  //   userModified: data.userModified,
  // };
  await privateFetch
    .put(URL.REQUEST_ROLE_UPDATE_DELETE(data.id), JSON.stringify(data),{
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_update_error);
    });
  return responseData;
};


export const deleteRole = async (id:number) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_ROLE_UPDATE_DELETE(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      //throw new Error(properties.com_parval_label_role_delete_error);
      console.log(error)
    });
  return responseData;
};
