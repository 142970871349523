import { properties } from '../../utils/Properties_es';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { formSchemaOptionsName } from '../../types/Utils';

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdateUser {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  status: string;
  roleID: {
    id?: number;
    name?: string;
  }[];
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  roleData?: any;
}

const AddOrUpdateUserModal = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
  roleData,
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    username: Yup.string().required('Campo es requerido').email('Email es invalido'),
    firstName: Yup.string().required('Campo es requerido'),
    lastName: Yup.string().required('Campo es requerido'),
    password: Yup.string().when(modalType, {
      is: 'create',
      then: Yup.string().required('Campo es requerido'),
    }),
    phone: Yup.string().required('Campo es requerido'),
    status: Yup.string(),
    roleID: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    username: data.username ? data.username : '',
    firstName: data.firstName ? data.firstName : '',
    lastName: data.lastName ? data.lastName : '',
    password: data.password ? data.password : '',
    phone: data.phone ? data.phone : '',
    status: data.status ? data.status : '',
    roleID: data.userRole
      ? roleData.find((f: any) => f.name === data.userRole)
      : "",
    activationCode: data.activationCode ? data.activationCode : '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateUser>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });

  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        {properties.com_parval_label_user_button_add_title}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'username'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Correo electrónico'
                    type='mail'
                    variant='standard'
                    value={value}
                    autoComplete='username3'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('username')}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'firstName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Nombre'
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='firstName'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('firstName')}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'lastName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Apellido'
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='lastName'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('lastName')}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            {modalType === 'create' && (
              <Grid item xs={12}>
                <Controller
                  name={'password'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size='small'
                      label='Contraseña'
                      type='password'
                      variant='standard'
                      value={value}
                      autoComplete='password'
                      sx={{ '& input': { pl: '0!important' } }}
                      {...register('password')}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Controller
                name={'phone'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Teléfono'
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='phone'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('phone')}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="roleID"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={roleData}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Perfil"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.roleID}
                          helperText={errors.roleID && errors.roleID.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateUserModal;
