import { properties } from "../../utils/Properties_es";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ContentCategory } from "../../types/Category";
import { useEffect } from "react";

const options = [
  { id: 0, name: "No aplica"},
  { id: 1, name: "1 año" },
  { id: 2, name: "2 años" },
  { id: 3, name: "3 años" },
  { id: 4, name: "4 años" },
  { id: 5, name: "5 años" },
  { id: 6, name: "6 años" },
  { id: 7, name: "7 años" },
  { id: 8, name: "8 años" },
  { id: 9, name: "9 años" },
  { id: 10, name: "10 años" },
]

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateRole {
  id?: number;
  description: string;
  name: string;
  acronym: string;
  lifespan: {
    id: number;
    name: string;
  };
  yearsDepreciation: {
    id: number;
    name: string;
  }
  anualDepreciation: string;
}

interface DialogProps {
  data: ContentCategory;
  onSubmit: any;
  cancelModal: () => void;
  modalType?: "create" | "update";
}

const AddOrUpdateCategoryModal = ({
  data,
  onSubmit,
  cancelModal,
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    description: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
    acronym: Yup.string().required("Campo es requerido").min(3, "El acrónimo debe tener 3 caracteres"),
    lifespan: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),
    yearsDepreciation: Yup.object().shape({
      id: Yup.number().required("Campo es requerido"),
      name: Yup.string().required("Campo es requerido"),
    }),


  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    description: data.description ? data.description : "",
    name: data.name ? data.name : "",
    acronym: data.acronym ? data.acronym : "",
    lifespan: options.find((option) => option.id === data.lifeTime) || options[0],
    yearsDepreciation: options.find((option) => option.id === data.yearsDepreciation) || options[0],
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue
  } = useForm<AddOrUpdateRole>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  const watchLifespan = watch("lifespan");

  useEffect(() => {
    if (watchLifespan.id === 0) {
      
      // setValue("anualDepreciation", (100 / watchLifespan.id).toFixed(2));
      setValue("anualDepreciation", "No aplica");
    }
    else {
      setValue("anualDepreciation", (100 / watchLifespan.id).toFixed(2));
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [watchLifespan])

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {data.id ? properties.com_parval_label_button_edit_category : properties.com_parval_label_button_add_category}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}

                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Descripción"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="description"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("description")}
                    error={errors.description && Boolean(errors.description)}
                    helperText={
                      errors.description && errors.description.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"acronym"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Acrónimo"
                    type="text"
                    variant="standard"
                    value={value.length > 3 ? value.substring(0, 3) : value}
                    autoComplete="acronym"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("acronym")}
                    error={errors.acronym && Boolean(errors.acronym)}
                    helperText={
                      errors.acronym && errors.acronym.message
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="yearsDepreciation"
                defaultValue={options[0]}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={options}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value?.id
                    }
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tiempo de depreciación (años)"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        error={!!errors.yearsDepreciation}
                        helperText={errors.yearsDepreciation && errors.yearsDepreciation.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                name="lifespan"
                defaultValue={options[0]}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={options}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value?.id
                    }
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tiempo de vida útil (años)"
                        variant="standard"
                        sx={{ "& input": { pl: "0!important" } }}
                        error={!!errors.lifespan}
                        helperText={errors.lifespan && errors.lifespan.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name={"anualDepreciation"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="% Depreciación anual"
                    type="text"
                    disabled
                    variant="standard"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("anualDepreciation")}
                    error={errors.anualDepreciation && Boolean(errors.anualDepreciation)}
                    helperText={
                      errors.anualDepreciation && errors.anualDepreciation.message
                    }
                  />
                )}
              />
            </Grid>


          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateCategoryModal;
