import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";

export const generateReport = async (id: number, startdate: string, endDate?: string, acummulated?: boolean ) => {

  try {
    const response = await privateFetch.get(
      URL.REQUEST_REPORT_GENERATE,
        {
          responseType: 'blob',
          params: {
            id: id,
            startDate: startdate,
            endDate: endDate || "",
            accumulated: acummulated

          }
        }
    );

    return response
  } catch (error: any) {

    throw new Error("No se encuentran datos para generar el reporte");

  }
};