import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodySection, ContentSection, SectionResponse } from "../../types/Section";

export const getSection = async (page: number, size?: number, filtro?: string): Promise<SectionResponse> => {

  try {
    const response: AxiosResponse<SectionResponse> = await privateFetch.get(
      URL.REQUEST_SECTION_LIST(
        page,
        size || UrlBase.registration_x_page
      ), {
      params: {
        filter: filtro || ""
      }
    }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_section_find_error);

  }
};

export const createSection = async (data: BodySection): Promise<ContentSection> => {

  try {
    const response: AxiosResponse<ContentSection> = await privateFetch.post(URL.REQUEST_SECTION_CREATE, {
      ...data,
      report: Number(data.report.id)
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_section_save_error);
  }
};

export const updateSection = async (data: BodySection): Promise<ContentSection> => {
  try {

    const response = await privateFetch.put(
      URL.REQUEST_SECTION_UPDATE_DELETE(data.id),
      data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_section_update_error);
  }
};


export const deleteSection = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_SECTION_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_section_delete_error);
  }
};

