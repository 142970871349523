import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  Button,
  Dialog,
} from '@mui/material';
import { TrashIcon, PlusIcon, PenIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import { useForm, Controller } from 'react-hook-form';

import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import DeleteModal from '../components/utils/DeleteModal';
import ResourceAccess from '../components/security/ResourceAccess';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import { BodyCategory, CategoryResponse, ContentCategory } from '../types/Category';
import { createCategory, deleteCategory, getCategories, updateCategory } from '../services/category/CategoryService';
import AddOrUpdateCategoryModal from '../components/utils/AddOrUpdateCategory';
import Loading from '../components/utils/Loading';
import { Box, TextField } from '@mui/material';

const initForm = {
  id: 0,
  description: '',
  name: '',
  acronym: '',
  lifespan: {
    id: 0,
    name: 'No aplica',
  },
  anualDepreciation: "",

};
type FormData = typeof initForm;

const CategoryManagement = () => {

  const {
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();

  const defaultValues = {
    search: '',
  };
  

  const [categodyData, setCategoryData] = useState<CategoryResponse>({} as CategoryResponse);
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>(initForm);
  const { handleSubmit, control, setValue, watch } = useForm();
  const [preFilter, setPreFilter] = useState(defaultValues);


  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);



  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number = 0, filtro?: string) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage || 0);

      let data = await getCategories(currentPage, 10, filtro);
      if (data) {
        setCategoryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: BodyCategory) => {
    handleCancelModal();
    console.log(data)
    setLoading && setLoading(true);
    try {
      let createData = await createCategory(
        {
          ...data,
          // @ts-ignore
          lifeTime: data.lifespan.id,
          // @ts-ignore
          yearsDepreciation: data.yearsDepreciation.id,
          annualPercentage: Number(data.anualDepreciation),
        }
      );

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_role_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any): Promise<void> => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      const updateData = await updateCategory(
        {
          ...data,
          // @ts-ignore
          lifeTime: data.lifespan.id,
          // @ts-ignore
          yearsDepreciation: data.yearsDepreciation.id,
          annualPercentage: Number(data.anualDepreciation),
        }
      );

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_category_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: number) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteCategory(id);
      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_category_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      await handleFetchData();
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */

  type ModalAction = 'update' | 'delete';
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name') as ModalAction;
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update') {
      object = categodyData.content.find((p) => p.id === parseInt(id));
      //@ts-ignore
      setFormData(object);
    }

    if (modalAction === 'delete') {
      object = categodyData.content.find((p) => p.id === parseInt(id));
    }
    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        // @ts-ignore
        modalObject: object,
      });
  };


  /**
   * @param event
   */
  const handleCancelModal = () => {
    setFormData(initForm);
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'delete':
        // @ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter.search);
    }
  };

  const handleFilter = async (e: any) => {
    const filtro = e.search
    setPreFilter({
      search: filtro,
    })

    if (filtro === '') {
      await handleFetchData(0);
    } else {
      await handleFetchData(0, filtro);
    }
  };

  const watchSearch = watch('search');

  const handleClearFilter = async (e: any) => {
    await handleFetchData(0);
    setPreFilter({
      search: '',
    })
    setValue('search', '');
  };



  return (
    <>
              {isLoading && (
        <Loading />
      )}
      <FancyPaper pagetitle='Categoria'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={8} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'CATEGORY:WRITE'}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenModal}
              data-name='create'>
              Agregar Categoria
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={4} sm={6} xs={12} >
            <Box display="flex" gap={4} justifyContent="flex-end">

              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      '& label': {
                        marginTop: '-5px'
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={watchSearch === '' ? true : false}
              >
                Buscar
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={handleClearFilter}
              >
                Limpiar
              </Button>
            </Box>


          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell align='center'>Descripción</TableCell>
                <TableCell align='center'>Acrónimo</TableCell>
                <TableCell align='center'>Años de Depreciación</TableCell>
                <TableCell align='center'>Tiempo de vida útil</TableCell>
                <TableCell align='center'>Depreciación anual</TableCell>
                <TableCell align='center'>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categodyData.content &&
                categodyData.content.map((row: ContentCategory, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align='center'>{row.description}</TableCell>
                    <TableCell align='center'>{row.acronym}</TableCell>
                    <TableCell align='center'>{row.yearsDepreciation ? `${row.yearsDepreciation} años` : `No aplica`}</TableCell>
                    <TableCell align='center'>{!!row.lifeTime ? `${row.lifeTime} años` : `No aplica`  }</TableCell>
                    <TableCell align='center'>{row.annualPercentage ? `${row.annualPercentage}%` : "No aplica"}</TableCell>
                    <TableCell align='center'>
                      <ResourceAccess isCode={true} pathOrCode={'CATEGORY:WRITE'}>
                      <FancyTooltip title='Editar' placement='top'>
                        <IconButton
                          aria-label='edit'
                          component='label'
                          color='secondary'
                          sx={{
                            '&:hover': {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          onClick={handleOpenModal}
                          data-name='update'
                          data-id={row.id}>
                          <PenIcon />
                        </IconButton>
                      </FancyTooltip>
                      <FancyTooltip title='Eliminar' placement='top'>
                        <IconButton
                          aria-label='trash'
                          component='label'
                          color='secondary'
                          sx={{
                            '&:hover': {
                              color: theme.palette.secondary.dark,
                            },
                          }}
                          data-id={row.id}
                          onClick={handleOpenModal}
                          data-name='delete'>
                          <TrashIcon />
                        </IconButton>
                      </FancyTooltip>
                      </ResourceAccess>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={categodyData?.content?.length > 0 ? categodyData?.content?.length : 0}
          rowsPerPage={categodyData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={categodyData.totalElements}
          totalPages={categodyData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateCategoryModal
          // @ts-ignore
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default CategoryManagement;
