import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constants";
import { UrlBase } from "../../url/Urls";
import { AxiosResponse } from "axios";
import { BodyAccount, ContentAccount, AccountResponse } from "../../types/Account";

export const uploadFileAccount = async (data: FormData, userCreated: string) => {

  try {
    const response = await privateFetch.post(
      URL.REQUEST_ACCOUNT_UPLOAD_FILE(userCreated),
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export const getAccounts = async (page: number, size?: number, filtro?: string): Promise<AccountResponse> => {

  try {
    const response: AxiosResponse<AccountResponse> = await privateFetch.get(
      URL.REQUEST_ACCOUNT_LIST(
        page,
        size || UrlBase.registration_x_page
      ), {
      params: {
        filter: filtro || ""
      }
    }
    );

    return response.data;
  } catch (error) {
    console.log(error)
    throw new Error(properties.com_parval_label_account_find_error);

  }
};

export const createAccount = async (data: BodyAccount): Promise<ContentAccount> => {

  try {
    const response: AxiosResponse<ContentAccount> = await privateFetch.post(URL.REQUEST_ACCOUNT_CREATE, {
      ...data,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_account_save_error);
  }
};

export const updateAccount = async (data: BodyAccount): Promise<ContentAccount> => {
  try {

    const response = await privateFetch.put(
      URL.REQUEST_ACCOUNT_UPDATE_DELETE(data.id),
      data, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_account_update_error);
  }
};


export const deleteAccount = async (id: number): Promise<string> => {
  try {
    await privateFetch.delete(URL.REQUEST_ACCOUNT_UPDATE_DELETE(id), {});
    return "deleted successfully"
  } catch (error) {
    console.log(error);
    throw new Error(properties.com_parval_label_account_delete_error);
  }
};

