import { useState } from 'react';
import {
  Button,
  Box,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import CancelIcon from '@mui/icons-material/Cancel';
import Loading from '../components/utils/Loading';
import { Typography } from '@mui/material';
import { uploadFileBalance } from '../services/balance/BalanceService';
import { useNavigate } from 'react-router-dom';
import { uploadFileProjected } from '../services/proyectado/ProjectedService';

const UploadFileManagement = () => {
  const { authInfo, isLoading, setLoading, setErrorMsg, setSuccessMsg } = useApp();

  const { handleSubmit, control, setValue } = useForm();
  const navigate = useNavigate();

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileSelect = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setValue('file', file);
    e.target.value = null;
  };

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      setLoading && setLoading(true);

      const name = authInfo ? authInfo.name : 'admin';

      const formData = new FormData();
      formData.append('file', data.file);

      let response = null;

      if (data.typeDocument === 'Balance')
        response = await uploadFileBalance(formData, name, data.deleteData);
      else {
        response = await uploadFileProjected(formData, name, data.deleteData);
      }

      setSuccessMsg && setSuccessMsg(response.message);

      navigate(data.typeDocument === 'Balance' ? '/balanceManagement' : '/presupuestadoManagement')
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
    } finally {
      setLoading && setLoading(false);
    }
  };

  const handleFileClear = () => {
    setSelectedFile(null);
    setValue('file', null);
  };

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle='Carga de archivos'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography>Carga de Archivos</Typography>

          <Box
            display='flex'
            flexDirection='column'
            // alignItems='center'
            maxWidth='450px'
            marginX='auto'>
            <Box width='340px' marginLeft='3rem' marginTop='1rem'>
              <Typography marginBottom='10px'>Tipo de Archivo</Typography>
              <Controller
                name='typeDocument'
                control={control}
                // defaultValue={false}
                defaultValue={'Balance'}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Select
                      value={value}
                      onChange={onChange}
                      fullWidth
                      // label='Tipo de documento'
                      id='
                demo-multiple-name-label'>
                      <MenuItem value='Balance'>Balance</MenuItem>
                      <MenuItem value='Presupuestado'>Presupuestado</MenuItem>
                    </Select>
                  </>
                )}
              />
            </Box>

            <Box
              display='flex'
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              marginTop='2rem'
              gap={5}>
              <Controller
                name='file'
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    // label="Subir archivo"
                    type='text'
                    variant='standard'
                    inputProps={{ maxLength: 50 }}
                    // @ts-ignore
                    value={selectedFile ? selectedFile.name : ''}
                    sx={{ width: '250px', '& input': { pl: '0!important' } }}
                    disabled
                    InputProps={{
                      endAdornment: selectedFile && (
                        <InputAdornment
                          position='end'
                          sx={{
                            cursor: 'pointer',
                          }}>
                          <CancelIcon onClick={handleFileClear} />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <label htmlFor='file-upload'>
                <input
                  id='file-upload'
                  name='file'
                  type='file'
                  accept='.xls, .xlsx'
                  style={{ display: 'none' }}
                  onChange={handleFileSelect}
                />
                <Button variant='contained' component='span'>
                  Subir
                </Button>
              </label>
            </Box>
            <Box display='flex' flexDirection='column' marginY='1.5rem' marginX='auto'>
              <Controller
                name='deleteData'
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: '1rem',
                      },
                    }}
                    control={
                      <Checkbox
                        color='primary'
                        checked={value}
                        onChange={e => {
                          onChange(e.target.checked);
                        }}
                      />
                    }
                    label='Actualizar datos existentes'
                  />
                )}
              />
            </Box>

            <Button
              type='submit'
              variant='contained'
              disabled={!selectedFile}
              sx={{
                width: '250px',
                marginX: 'auto',
              }}>
              Cargar
            </Button>
          </Box>
        </form>
      </FancyPaper>
    </>
  );
};

export default UploadFileManagement;
