import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import DashboardHome from "../layouts/DashboardHome";
import UserManagement from "../pages/UserManagement";
// import Reports from "../pages/Reports";
import RoleManagement from "../pages/RoleManagement";
import EmailRoleManagement from "../pages/EmailRoleManagement";

import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DocumentsRoleManagement from "../pages/DocumentsRoleManagement";
import AuthorityManagement from "../pages/AuthorityManagement";
import AuthorityByRoleManagement from "../pages/AuthorityByRoleManagement";
// import ClientManagement from "../pages/ClientManagement";
import Dashboard from "../pages/Dashboard";
import OfficeManagement from "../pages/OfficeManagement";
import ProviderManagement from "../pages/ProviderManagement";
import CategoryManagement from "../pages/CategoryManagement";
import ReportManagement from "../pages/ReportManagement";
import UploadFileManagement from "../pages/UploadFileManagement";
import SectionManagement from "../pages/SectionManagement";
import AccountManagement from "../pages/AccountManagement";
import RenglonManagement from "../pages/RenglonManagement";
import BalanceManagement from "../pages/BalanceManagement";
import AreaManagement from "../pages/AreaManagement";
import Report from "../pages/Report";
import PresupuestadoManagement from "../pages/PresupuestadoManagement";
const Session = lazy(() => import("../pages/session/Session"));
/** Utils page */
const NotFound = lazy(() => import("../pages/utils/NotFound"));

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/session" element={<Session />} />
      <Route path="/" element={<DashboardHome />}>
        <Route index element={<Dashboard />} />
        <Route path="/userManagement" element={<UserManagement />} />
        <Route path="/roleManagement" element={<RoleManagement />} />
        <Route path="/sectionManagement" element={<SectionManagement />} />
        <Route path="/uploadFileManagement" element={<UploadFileManagement />} />
        <Route path="/accountManagement" element={<AccountManagement />} />
        <Route path="/renglonManagement" element={<RenglonManagement />} />
        <Route path="/balanceManagement" element={<BalanceManagement />} />
        <Route path="/presupuestadoManagement" element={<PresupuestadoManagement />} />
        <Route path="/categoryManagement" element={<CategoryManagement />} />
        <Route path="/reportManagement" element={<ReportManagement />} />
        <Route path="/report/:reportId" element={<Report />} />
        <Route path="/areaManagement" element={<AreaManagement />} />
        <Route path="/providerManagement" element={<ProviderManagement />} />
        <Route path="/officeManagement" element={<OfficeManagement />} />
        <Route path="/emailRoleManagement" element={<EmailRoleManagement />} />
        <Route path="/documentsRole" element={<DocumentsRoleManagement />} />
        <Route path="/authority" element={<AuthorityManagement />} />
        <Route
          path="/authoritiesbyrole/:roleId"
          element={<AuthorityByRoleManagement />}
        />
      </Route>
      <Route path="/notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />
    </Route>
  )
);
