import { CONSTANT } from "./Constants";
export const URL = {

    /** HOME PATH */
    LOGIN_TWOFACTORCODE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/login/twofactor",
    LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/autenticate",
    LOGIN_RECOVERY_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/recoverPasswordAdmin",
    LOGIN_CHANGE_PASS_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/changePassword",
    LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX +  "/user/activate",
    SESSIONVALIDATE_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

    /** maintenance urls */
    REQUEST_CERTCATEGORY_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${page}/${size}`,
    REQUEST_CERTCATEGORY: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificate-categories/${id ? id : ''}`,

    REQUEST_CERTIFICATE_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${page}/${size}`,
    REQUEST_CERTIFICATE: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/certificates/${id ? id : ''}`,

    // Assets

    REQUEST_ASSET_LIST:  CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/`,
    REQUEST_ASSET_GET_BY_ID: (id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/${id}`,
    REQUEST_ASSET_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets`,
    REQUEST_ASSET_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/assets/${id}`,

    // Maintenance Area

    REQUEST_AREA_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_AREA_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area`,
    REQUEST_AREA_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/area/${id}`,

    // Maintenance Section

    REQUEST_SECTION_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/section/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_SECTION_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/section`,
    REQUEST_SECTION_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/section/${id}`,

    // Field Section

    REQUEST_FIELD_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/field/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_FIELD_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/field`,
    REQUEST_FIELD_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/field/${id}`,

    // Maintenance Accounts

    REQUEST_ACCOUNT_UPLOAD_FILE: (userCreated:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account/upload?user_created=${userCreated}`,
    REQUEST_ACCOUNT_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_ACCOUNT_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account`,
    REQUEST_ACCOUNT_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/account/${id}`,

    // Maintenance Category

    REQUEST_CATEGORY_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_CATEGORY_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category`,
    REQUEST_CATEGORY_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/category/${id}`,

    // Maintenance Office

    REQUEST_OFFICE_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_OFFICE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office`,
    REQUEST_OFFICE_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/office/${id}`,

    // Maintenance Report

    REQUEST_REPORT_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/report/filter/?page=${page}&size=${size}`,
    REQUEST_REPORT_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/report`,
    REQUEST_REPORT_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/report/${id}`,

    //  Report Generate Report 

    REQUEST_REPORT_GENERATE: CONSTANT.DISPATCHER_REPORT_PREFIX + `/generatereport/download/`,
    

    // Maintenance Provider

    REQUEST_PROVIDER_LIST: (page:number,size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_PROVIDER_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider`,
    REQUEST_PROVIDER_UPDATE_DELETE:(id: number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/provider/${id}`,

    /** users urls */
    
    REQUEST_USER_LIST: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/{page}/{size}/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_USER_LIST_FILTER: (page:string,size:string) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
    REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
    REQUEST_USER: (id:any) => CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ''}`,
    REQUEST_USER_CHANGE_PASSWORD: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
    REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,

  


    /** Role urls */
    REQUEST_ROLE: (id:number | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role${id ? id : ''}`,
    REQUEST_ROLE_ID: (id:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/filter/?filter=${id}`,
    REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role`,
    REQUEST_ROLE_UPDATE_DELETE: (id:string | number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/role/${id}`,
  

    

    /** EmailRole urls */
    REQUEST_EMAIL_ROLE: (roleID?:string|null, filter?:string|null, currentPage?:number, size?:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${roleID ? roleID+'/' : ''}?${filter ? 'filter='+filter+'&' : ''}page=${currentPage}&size=${size}`,
    REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/findById/${id ? id : ''}`,
    DELETE_REQUEST_EMAIL_ROLE_ID: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/${id ? id : ''}`,
    REQUEST_EMAIL_ROLE_CU: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/email/`,

    /** DocumentRole urls */
    REQUEST_DOCUMENT_ROLE_LIST: (filter:string|null, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/list/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}`,
    REQUEST_DOCUMENT_ROLE: (id:string | null) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documentsRole/${id ? id : ''}`,
    
    // Balance urls

    REQUEST_BALANCE_UPLOAD_FILE: (userCreated:string, remove: boolean) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/balance/upload?user_created=${userCreated}&actual_remove=${remove}`,

    REQUEST_BALANCE_LIST: (page:number, size?:number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/balance/filter/?page=${page}&size=${size}&sort=id,desc`,
    REQUEST_LAST_BALANCE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/balance/findDateUltReport`,


    // Projected urls
    REQUEST_PROJECTED_UPLOAD_FILE: (userCreated:string, remove: boolean) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/projected-report/upload?user_created=${userCreated}&actual_remove=${remove}`,
    REQUEST_PROJECTED_LIST: (page:number, size?:number) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/projected-report/filter/?page=${page}&size=${size}&sort=id,desc`,
    /** document */
    REQUEST_DOCUMENT_LIST: (page:number, size:string) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/list/?page=${page}&size=${size}`,
    REQUEST_DOCUMENT: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/${id ? id : ""}`,
    REQUEST_DOCUMENT_UPD: (id: string | null) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/documents/{id}`,

    /** authority */
    REQUEST_AUTHORITY_LIST: (filter:string, page:number, size:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/?${filter ? 'filter='+filter+'&' : ""}page=${page}&size=${size}&sort=id,desc`,    
    REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
    REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (roleId:number, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${roleId}/${page}/${size}?filter=${filter}`,

   

    /** authorityByRole */    
    REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && '?filter='+filter}`,
    /* REQUEST_AUTHORITY_BY_ROLE_LIST: (roleId:string, page:number, size:number, filter:string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${roleId}/${page}/${size}${filter && 'filter='+filter}`, */
    
    
    REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

    REQUEST_CLIENT_LIST: (endDate:any, page:number, size:string, startDate:any, ) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/{page}/{size}/?endDate=${endDate}&page=${page}&size=${size}&startDate=${startDate}`,
    REQUEST_CLIENT: (id:any) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/${id ? id : ''}`,
    /* REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}/${page}/${size}`,   */
    REQUEST_STORES_CLIENT: (id:string, page:number, size:string) => CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/client/stores/${id ? id : ''}?page=${page}&size=${size}`,  
    
    
    /**Dashboard */
  REQUEST_CATEGORY_COUNT: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/category-count`,
  REQUEST_USER_WITH_ASSETS : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/users-with-assets`,
  REQUEST_ASSETS_BY_CATEGORY : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-category`,
  REQUEST_PROVIDER_COUNT : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/provider-count`,
  REQUEST_ASSETS_BY_MONTH : CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/assets-by-month`,


    REQUEST_CUSTOMER_STAGE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-stage`,      
    REQUEST_CUSTOMER_PERSON_TYPE: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/customer-person-type`,  
    REQUEST_CUSTOMER_REGISTERED: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer`,  
    REQUEST_CUSTOMER_COMPARATION: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/clients-comparation`,     
    REQUEST_CUSTOMER_YEAR: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/registered-customer-year`,     
    REQUEST_CUSTOMER_LEADS: CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/dashboard/leads`,     
}

