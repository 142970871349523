import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
} from '@mui/material';
import { KeyIcon, TrashIcon, PlusIcon, PenIcon } from '../components/svgicons/SvgIcons';
import FancyTooltip from '../components/utils/FancyTooltip';
import FancyPaper from '../components/FancyPaper';
import { useForm } from 'react-hook-form';
import { getRoles } from '../services/role/RoleService';
import {
  getRequestUser,
  createRequest,
  findByIdRequest,
  updateRequest,
  updatePassRequest,
  deleteRequest,
} from '../services/users/UserService';
import { properties } from '../utils/Properties_es';
import { useApp } from '../hooks/useApp';
import AddOrUpdateUserModal from '../components/utils/AddOrUpdateUserModal';
import DeleteModal from '../components/utils/DeleteModal';
import UpdateUserPassModal from '../components/utils/UpdateUserPassModal';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import ResourceAccess from '../components/security/ResourceAccess';
import Loading from '../components/utils/Loading';

const CustomerManagement = () => {
  const {
    isLoading,
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const theme = useTheme();
  const [usersData, setUsersData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>('');
  const [page, setPage] = useState(0);
  const [userRole, setUserRole] = useState<any>([]);

  const initForm = {
    id: 0,
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    phone: null,
    status: 'ACTIVE',
    roleID: null,
    activationCode: '',
  };
  const [formData, setFormData] = useState<any>(initForm);

  const { handleSubmit} = useForm();

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0, '');
    };

    dataInit();

    // eslint-disable-next-line
  }, []);

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== '') {
      await handleFetchData(0, preFilter);
    }
  };

  /**
   *
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filter: string, applyRole?: any) => {
    setLoading && setLoading(true);
    try {
      setPreFilter(filter);
      setPage(currentPage);

      let userRoleData = await getRoles();
      if (userRoleData) {
        //@ts-ignore
        setUserRole(userRoleData.content);
      }
      let data = await getRequestUser(currentPage, filter);
      if (data) {
        setUsersData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleFetchByID = async (id: string) => {
    setLoading && setLoading(true);

    try {
      let userData = await findByIdRequest(id);
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de crear registro
   * @param data
   */
  const handleAdd = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
      });

      if (!createData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_save_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0, '');
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
      console.log(error);
    }
  };

  /**
   * Metodo encargado de actualizar registro
   * @param data
   */
  const handleUpdate = async (data: any) => {
    console.log(data);
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      let updateData = await updateRequest({
        ...data,
        modifierUser: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_update);
      //call fetch data
      await handleFetchData(0, '');
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de actualizar password del usuario
   * @param data
   */
  const handlePassUpdate = async (data: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let updateData = await updatePassRequest(data);

      if (!updateData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_forgotpass_general_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_forgotpass_general_sucess);

      //call fetch data
      await handleFetchData(0, '');
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Metodo encargado de eliminar registro
   * @param data
   */
  const handleDelete = async (id: any) => {
    //close modal
    handleCancelModal();
    //loading
    setLoading && setLoading(true);
    try {
      //call service
      let deleteData = await deleteRequest(id);

      if (!deleteData) {
        setErrorMsg && setErrorMsg(properties.com_parval_label_user_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg(properties.com_parval_label_request_delete);

      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, preFilter);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute('data-name');
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    if (modalAction === 'update' || modalAction === 'passchange') {
      await handleFetchByID(id);
    }

    if (modalAction === 'delete') {
      object = usersData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== 'delete') {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case 'create':
        await handleAdd(data);
        break;
      case 'update':
        await handleUpdate(data);
        break;
      case 'passchange':
        await handlePassUpdate(data);
        break;
      case 'delete':
        //@ts-ignore
        await handleDelete(modalData?.modalObject?.id);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== 'delete') {
      setFormData(formData);
    }
  };

  return (
    <>
          {isLoading && (
        <Loading />
      )}
      {/* <img src={Banner} width="100%" /> */}
      <FancyPaper pagetitle='Usuarios'>
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            <ResourceAccess isCode={true} pathOrCode={'USER:WRITE'}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleOpenModal}
              data-name='create'>
              Agregar usuario
              <PlusIcon sx={{ ml: 1 }} />
            </Button>
            </ResourceAccess>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack direction='row' alignItems='center' justifyContent='flex-end'>
              <TextField
                placeholder='Filtro de búsqueda'
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '0.2rem 0 0 0.2rem',
                  },
                  '& fieldset': {
                    borderRightWidth: '0',
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === '') {
                    handleFetchData(0, '');
                  }
                }}
                onKeyDown={e => e.key === 'Enter' && handleApplyFilter()}
              />
              <Button
                variant='contained'
                color='secondary'
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: '0 0.2rem 0.2rem 0!important',
                  padding: '1.5px 16px!important',
                }}>
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell align='center'>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align='center'>Nombre</TableCell>
                <TableCell align='center'>Apellido</TableCell>
                <TableCell align='center'>Teléfono</TableCell>
                <TableCell align='center'>Estado</TableCell>
                <TableCell align='center'>Perfil</TableCell>
                {/* <ResourceAccess isCode={true} pathOrCode={'USER:WRITE'}> */}
                <TableCell align='center'>Acciones</TableCell>
                {/* </ResourceAccess> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {usersData &&
                usersData.content &&
                usersData.content
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, i: number) => (
                    <TableRow
                      key={i}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        {row.id}
                      </TableCell>
                      <TableCell>{row.username}</TableCell>
                      <TableCell align='center'>{row.firstName}</TableCell>
                      <TableCell align='center'>{row.lastName}</TableCell>
                      <TableCell align='center'>{row.phone}</TableCell>
                      <TableCell align='center'>{row.status}</TableCell>
                      <TableCell align='center'>{row.userRole}</TableCell>
                      <TableCell align='center'>
                        <ResourceAccess isCode={true} pathOrCode={'USER:WRITE'}>
                        <FancyTooltip title='Editar' placement='top'>
                          <IconButton
                            aria-label='edit'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            onClick={handleOpenModal}
                            data-name='update'
                            data-id={row.id}>
                            <PenIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title='Actualizar Constraseña' placement='top'>
                          <IconButton
                            aria-label='sorter'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='passchange'>
                            <KeyIcon />
                          </IconButton>
                        </FancyTooltip>
                        <FancyTooltip title='Eliminar' placement='top'>
                          <IconButton
                            aria-label='trash'
                            component='label'
                            color='secondary'
                            sx={{
                              '&:hover': {
                                color: theme.palette.secondary.dark,
                              },
                            }}
                            data-id={row.id}
                            onClick={handleOpenModal}
                            data-name='delete'>
                            <TrashIcon />
                          </IconButton>
                        </FancyTooltip>
                        </ResourceAccess>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={usersData?.content?.length > 0 ? usersData?.content?.length : 0}
          rowsPerPage={usersData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={usersData.totalElements}
          totalPages={usersData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === 'create' || modalData?.modalType === 'update') && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <AddOrUpdateUserModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
            roleData={userRole}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'passchange' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <UpdateUserPassModal
            data={formData}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === 'delete' && (
        <Dialog open={modalData.modalOpen} onClose={handleCancelModal} fullWidth>
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                {' '}
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  sx={{
                    mt: 2,
                    mr: 2,
                  }}
                  onClick={handleCancelModal}
                  autoFocus>
                  Cancelar
                </Button>{' '}
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default CustomerManagement;
