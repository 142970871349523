import { useState, useEffect } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  TextField,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  styled,
  Typography,
  DialogActions,
} from '@mui/material';
import FancyPaper from '../components/FancyPaper';
import { Controller, useForm } from 'react-hook-form';
import { useApp } from '../hooks/useApp';
import FancyTablePagination from '../components/utils/FancyTablePagination';
import Loading from '../components/utils/Loading';
import { SectionResponse } from '../types/Section';
import moment from 'moment';
import { getProjected } from '../services/proyectado/ProjectedService';
import FancyTooltip from '../components/utils/FancyTooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Title } from '@mui/icons-material';

const defaultValues = {
  search: '',
};

const Text = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.greyDue.main,
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  textAlign: 'center',
  fontSize: '17px',
  padding: theme.spacing(4),
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

const PresupuestadoManagement = () => {
  const { isLoading, setLoading, setErrorMsg, modalData, setModalData } = useApp();
  const [projectedData, setProjectedData] = useState<SectionResponse>({} as SectionResponse);
  const [page, setPage] = useState<number>(0);
  const [preFilter, setPreFilter] = useState(defaultValues);
  const [projectedModalData, setProjectedModalData] = useState<any>(null);

  /**
   * Efecto inicial para carga de registros
   */
  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData(0);
    };
    dataInit();

    // eslint-disable-next-line
  }, []);

  const { handleSubmit, control, setValue, watch } = useForm();

  /**
   * Metodo encargado de buscar registros
   * @param filter
   * @param currentPage
   */
  const handleFetchData = async (currentPage: number, filtro?: string) => {
    setLoading && setLoading(true);
    try {
      setPage(currentPage || 0);

      let data = await getProjected(currentPage, 10, filtro);

      if (data) {
        setProjectedData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
      console.log(error);
    }
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter.search);
    }
  };

  const handleFilter = async (e: any) => {
    const filtro = e.search;
    const startDate = e.startDate ? moment(e.startDate).format('MM/YYYY') : '';
    const endDate = e.endDate ? moment(e.endDate).format('MM/YYYY') : '';

    console.log(startDate, endDate);

    setPreFilter({
      search: filtro,
    });
    await handleFetchData(0, filtro);
  };

  const watchSearch = watch('search');

  const handleClearFilter = async (e: any) => {
    await handleFetchData(0);
    setPreFilter({
      search: '',
    });
    setValue('search', '');
  };

  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    let object = null;
    const id = event.currentTarget.getAttribute('data-id');

    const searchActivo = projectedData.content.find((item: any) => item.id === parseInt(id));
    setProjectedModalData(searchActivo);

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: 'delete',
        // @ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null,
      });
  };

  return (
    <>
      {isLoading && <Loading />}
      <FancyPaper pagetitle='Presupuestado'>
        <Grid container sx={{ pb: 8 }}>
          <Grid item md={12} sm={6} xs={12} display='flex' justifyContent='flex-end'>
            <Box display='flex' gap={4} justifyContent='flex-end' alignItems='center'>
              <Controller
                name='search'
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant='outlined'
                    size='small'
                    sx={{
                      width: '100%',
                      '& label': {
                        marginTop: '-5px',
                      },
                    }}
                  />
                )}
              />

              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit(handleFilter)}
                disabled={!watchSearch}>
                Filtrar
              </Button>
              <Button variant='contained' color='primary' onClick={handleClearFilter}>
                Limpiar
              </Button>
            </Box>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell align='center'>Reporte</TableCell>
                <TableCell align='center'>Sección</TableCell>
                <TableCell align='center'>Linea</TableCell>
                <TableCell align='center'>Año</TableCell>
                <TableCell align='center'>Ver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectedData.content &&
                projectedData.content.map((row: any, i: number) => (
                  <TableRow
                    key={i}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}>
                    <TableCell align='center'>{row.report}</TableCell>
                    <TableCell align='center'>{row?.section}</TableCell>
                    <TableCell align='center'>{row.line}</TableCell>
                    <TableCell align='center'>{row.year}</TableCell>
                    <TableCell align='center'>
                      <FancyTooltip title='Ver información' placement='top'>
                        <IconButton
                          aria-label='trash'
                          component='label'
                          color='secondary'
                          data-id={row.id}
                          onClick={handleOpenModal}
                          data-name='Ver información'>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </FancyTooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <FancyTablePagination
          count={projectedData?.content?.length > 0 ? projectedData?.content?.length : 0}
          rowsPerPage={projectedData.size}
          page={page}
          onPageChange={handleChangePage}
          totalElements={projectedData.totalElements}
          totalPages={projectedData.totalPages}
        />
      </FancyPaper>
      {!!projectedModalData && (
        <Dialog open={modalData!!.modalOpen} onClose={handleCancelModal} fullWidth>
          <Title
            id='alert-dialog-title'
            sx={{
              pt: 4,
              px: 4,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}>
            <RemoveRedEyeIcon />
            Simulador de Activos Fijos
          </Title>
          <DialogContent>
            <Text id='alert-dialog-description'>
              <Box
                sx={{
                  textAlign: 'left',
                  fontSize: '17px',
                }}>
                <Typography fontSize='inherit'>Reporte: {projectedModalData.report}</Typography>
                <Typography fontSize='inherit'>Sección: {projectedModalData.section}</Typography>
                <Typography fontSize='inherit'>Linea: {projectedModalData.line}</Typography>
                <Typography fontSize='inherit'>
                  Año: <b>{projectedModalData.year}</b>
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>Mes</TableCell>
                      <TableCell align='center'>Monto</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align='center'>Enero</TableCell>
                      <TableCell align='center'>{projectedModalData.january}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Febrero</TableCell>
                      <TableCell align='center'>{projectedModalData.february}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Marzo</TableCell>
                      <TableCell align='center'>{projectedModalData.march}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Abril</TableCell>
                      <TableCell align='center'>{projectedModalData.april}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Mayo</TableCell>
                      <TableCell align='center'>{projectedModalData.may}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Junio</TableCell>
                      <TableCell align='center'>{projectedModalData.june}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Julio</TableCell>
                      <TableCell align='center'>{projectedModalData.july}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Agosto</TableCell>
                      <TableCell align='center'>{projectedModalData.august}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Septiembre</TableCell>
                      <TableCell align='center'>{projectedModalData.september}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Octubre</TableCell>
                      <TableCell align='center'>{projectedModalData.october}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align='center'>Noviembre</TableCell>
                      <TableCell align='center'>{projectedModalData.november}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align='center'>Diciembre</TableCell>
                      <TableCell align='center'>{projectedModalData.december}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Text>
          </DialogContent>
          <Actions sx={{ pb: 4, px: 4 }}>
            <Button
              variant='contained'
              color='secondary'
              sx={{
                mt: 2,
              }}
              onClick={handleCancelModal}
              autoFocus>
              Cerrar
            </Button>{' '}
          </Actions>
        </Dialog>
      )}
    </>
  );
};

export default PresupuestadoManagement;
