import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ContentReport, ModalReport } from '../../types/Report';
import { formSchemaOptionsName } from '../../types/Utils';
import { optionFilter } from '../../utils/DataCheckbox';


const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: '#e1e8ee',
  color: '#3e627c!important',
  fontFamily: 'D-dinExp',
  fontWeight: 400,
  fontSize: '17.5px',
}));
const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: '1px solid #dee2e6',
}));

interface AddOrUpdateReport {
  id?: number;
  name: string;
  code: string;
  position: number;
  typeFilter: {
    id: string;
    name: string;
  }
}

interface DialogProps {
  actionButton?: any;
  data: ContentReport;
  onSubmit: (data: ModalReport) => Promise<void>
  cancelModal: any;
  modalType?: any;
}

const AddOrUpdateReportsModal = ({
  data,
  onSubmit,
  cancelModal,
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required('Campo es requerido'),
    code: Yup.string().required('Campo es requerido'),
    position: Yup.string().required('Campo es requerido'),
    typeFilter: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : '',
    code: data.code ? data.code : '',
    typeFilter: optionFilter.find((item) => item.id === data.typeFilter) ? optionFilter.find((item) => item.id === data.typeFilter) : { id: '', name: '' },
    userCreated: data.userCreated ? data.userCreated : '',
    position: data.position ? data.position : 1,

  };

  const {
    register,
    handleSubmit,
    formState: { errors, },
    control,
  } = useForm<AddOrUpdateReport>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: 'onChange',
  });


  return (
    <>
      <Title id='alert-dialog-title' sx={{ pt: 4, px: 4 }}>
        Agregar Reporte
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={4} maxWidth='lg' sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={'name'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Nombre'
                    type='text'
                    variant='standard'
                    inputProps={{ maxLength: 50 }}
                    value={value}
                    autoComplete='name'
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('name')}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'code'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Código'
                    type='text'
                    variant='standard'
                    value={value}
                    autoComplete='code'
                    inputProps={{ maxLength: 20 }}
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('code')}
                    error={errors.code && Boolean(errors.code)}
                    helperText={errors.code && errors.code.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="typeFilter"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={optionFilter}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de Filtro"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.typeFilter}
                          helperText={errors.typeFilter && errors.typeFilter.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'position'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size='small'
                    label='Posición'
                    type='number'
                    variant='standard'
                    value={value}
                    autoComplete=''
                    inputProps={{ 
                      maxLength: 2,
                      min: 1,
                      max: 99,
                     }}
                    sx={{ '& input': { pl: '0!important' } }}
                    {...register('position')}
                    error={errors.position && Boolean(errors.position)}
                    helperText={errors.position && errors.position.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant='contained'
          color='primary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
        <Button
          variant='contained'
          color='secondary'
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus>
          Cancelar
        </Button>{' '}
      </Actions>
    </>
  );
};

export default AddOrUpdateReportsModal;
