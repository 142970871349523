import {
  HouseIcon,
  GearIcon,
  TableIcon,
  FromBracketIcon,
  ChartIcon,
} from '../components/svgicons/SvgIcons';
import { properties } from './Properties_es';

import { MenuItem } from '../types/Menu';
import { getReports } from '../services/report/ReportService';

async function getReportMenuItems() {
  const response = await getReports(0, 100);

  const menuItemsReport = response.page?.content.map(item => {
    return {
      label: item.name,
      to: `/report/${item.id}`,
      // to: '',
      permissions: ['is_superuser', 'ACTIVO:WRITE'],
    };
  });
  return menuItemsReport;
}

getReportMenuItems().then(menuItemsReport => {
  MENUITEMS[1].children = menuItemsReport;
});

export const MENUITEMS: MenuItem[] = [
  {
    label: 'Inicio',
    icon: <HouseIcon sx={{ transform: 'translateY(-1px)', fontSize: '0.9225rem' }} />,
    to: '/',
    permissions: ['is_superuser', 'is_user'],
  },
  {
    label: 'Reportes',
    icon: <ChartIcon />,
    to: null,
    permissions: ['is_superuser', 'REPORT:READ'],
    expand: true,
    children: [],
  },
  {
    label: 'Mantenimientos',
    icon: <TableIcon />,
    to: null,
    expand: true,
    children: [
      {
        label: 'Carga de archivos',
        to: 'uploadFileManagement',
        permissions: ['is_superuser', 'UPLOADFILE:VIEW'],
      },
      {
        label: 'Balance',
        to: 'balanceManagement',
        permissions: ['is_superuser', 'BALANCE:READ'],
      },
      {
        label: 'Cuentas',
        to: 'accountManagement',
        permissions: ['is_superuser', 'ACCOUNT:READ'],
      },
      {
        label: 'Presupuestado',
        to: 'presupuestadoManagement',
        permissions: ['is_superuser', 'PRESUPUESTADO:READ'],
      },
      {
        label: 'Oficinas',
        to: 'officeManagement',
        permissions: ['is_superuser', 'OFFICE:READ'],
      },
      {
        label: 'Areas',
        to: 'areaManagement',
        permissions: ['is_superuser', 'AREA:READ'],
      },
      {
        label: 'Reportes',
        to: 'reportManagement',
        permissions: ['is_superuser', 'REPORT:READ'],
      },

      {
        label: 'Secciones',
        to: 'sectionManagement',
        permissions: ['is_superuser', 'SECTION:READ'],
      },

      {
        label: 'Renglones',
        to: 'renglonManagement',
        permissions: ['is_superuser', 'RENGLON:READ'],
      },
    ],
    permissions: [
      'is_superuser',
      'OFFICE:READ',
      'REPORT:READ',
      'SECTION:READ',
      'UPLOADFILE:VIEW',
      'BALANCE:READ',
      'ACCOUNT:READ',
      'AREA:READ',
      'RENGLON:READ',
    ],
  },
  {
    label: 'Configuraciones',
    icon: <GearIcon sx={{ transform: 'translateY(-1px)', fontSize: '1.025rem' }} />,
    to: null,
    expand: true,
    children: [
      {
        label: 'Usuarios',
        to: 'userManagement',
        permissions: ['is_superuser', 'USER:READ'],
      },
      {
        label: 'Perfiles',
        to: 'roleManagement',
        permissions: ['is_superuser', 'ROLE:READ'],
      },
      {
        label: 'Permisos',
        to: 'authority',
        permissions: ['is_superuser', 'AUTHORITY:READ'],
      },
    ],
    permissions: [
      'is_superuser',
      'USER:READ',
      'ROLE:READ',
      'AUTHORITY:READ',
      'AUTHORITIESXROLE:READ',
    ],
  },
  {
    label: properties.com_parval_label_dashboard_menu_logout,
    icon: <FromBracketIcon />,
    to: null,

    permissions: ['is_superuser', 'is_user'],
  },
];
