export const dataCommun = [
  {
    id: 0,
    name: "No",
  },
  {
    id: 1,
    name: "Si",
  },
]

export const dataDebitOrCredit = [
  {
    id: 1,
    name: "Débito",
  },
  {
    id: 2,
    name: "Crédito",
  },
]

export const optionFilter = [
  { id: 'mes', name: "Mensual" },
  { id: 'anual', name: "Rango por meses" },
]