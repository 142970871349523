import { properties } from "../../utils/Properties_es";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  styled,
  Autocomplete,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formSchemaOptionsName } from "../../types/Utils";
import { dataCommun, dataDebitOrCredit } from "../../utils/DataCheckbox";

const Title = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#e1e8ee",
  color: "#3e627c!important",
  fontFamily: "D-dinExp",
  fontWeight: 400,
  fontSize: "17.5px",
}));

const Actions = styled(DialogActions)(({ theme }) => ({
  borderTop: "1px solid #dee2e6",
}));

interface AddOrUpdateSection {
  id?: number;
  name: string;
  code: string;
  group: string;
  report: {
    id?: number;
    name?: string;
  }[];
  section: {
    id?: number;
    name?: string;
  }[];
  expenses: {
    id?: number;
    name?: string;
  }
  debitCredit: {
    id?: number;
    name?: string;
  }

}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType?: any;
  reportData: any[]
  sectionData: any[]
}

const AddOrUpdateRenglonModal = ({
  data,
  onSubmit,
  cancelModal,
  reportData,
  sectionData
}: DialogProps) => {

  //Validación de datos
  const validation = Yup.object().shape({
    name: Yup.string().required("Campo es requerido"),
    group: Yup.string().required("Campo es requerido"),
    code: Yup.string().required("Campo es requerido"),
    report: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    section: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    debitCredit: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),
    expenses: Yup.object()
      .shape(formSchemaOptionsName)
      .typeError("Campo es requerido"),

  });

  const defaultValues = {
    id: data.id ? data.id : 0,
    name: data.name ? data.name : "",
    report: data.report
    ? reportData.find((x: any) => x.id === data.report)
    : reportData[0],
    section: data.section
    ? sectionData.find((x: any) => x.id === data.section)
    : sectionData[0],
    debitCredit: data.debitCredit
    ? dataDebitOrCredit.find((x: any) => x.id === data.debitCredit)
    : dataDebitOrCredit[0],
    expenses: data.expenses
    ? dataCommun.find((x: any) => x.id === data.expenses)
    : dataCommun[0],
    code: data.code ? data.code : "",
    group: data.group ? data.group : "",
    userCreated: data.userCreated ? data.userCreated : "",


  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateSection>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <Title id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {data.id ?  properties.com_parval_label_button_edit_renglon : properties.com_parval_label_button_add_renglon}
      </Title>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12}>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="name3"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("name")}
                    error={errors.name && Boolean(errors.name)}
                    helperText={errors.name && errors.name.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"code"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{
                      maxLength: 20,
                    }}
                    label="Código"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("code")}
                    error={errors.code && Boolean(errors.code)}
                    helperText={errors.code && errors.code.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="report"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={ reportData || []}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id 
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Reporte"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.report}
                          helperText={errors.report && errors.report.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="section"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={ sectionData || []}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id 
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sección"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.section}
                          helperText={errors.section && errors.section.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="debitCredit"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataDebitOrCredit}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Débito/Crédito"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.debitCredit}
                          helperText={errors.debitCredit && errors.debitCredit.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                defaultValue={{
                  // @ts-ignore
                  id: 0, name: "",
                }}
                name="expenses"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue);
                    }}
                    options={dataCommun}
                    getOptionLabel={(option: any) => option.name || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    //@ts-ignore
                    value={value}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Gastos"
                          variant="standard"
                          sx={{ "& input": { pl: "0!important" } }}
                          error={!!errors.expenses}
                          helperText={errors.expenses && errors.expenses.message}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>            <Grid item xs={12}>
              <Controller
                name={"group"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    inputProps={{
                      maxLength: 5,
                    }}
                    label="Grupo"
                    type="text"
                    variant="standard"
                    value={value}
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("group")}
                    error={errors.group && Boolean(errors.group)}
                    helperText={errors.group && errors.group.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <Actions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </Actions>
    </>
  );
};

export default AddOrUpdateRenglonModal;
