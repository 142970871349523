import { useState, createContext, useMemo, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestTypes } from '../types/Context';
import { setAuthData } from '../utils/LocalStorageManager';
import jwt_decode from 'jwt-decode';
import { UrlBase } from '../url/Urls';

const initialState = {
  isLoading: false,
  errorMsg: '',
  successMsg: '',
};

const AppContext = createContext<requestTypes>(initialState);
const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(initialState.isLoading);
  const [errorMsg, setErrorMsg] = useState(initialState.errorMsg);
  const [successMsg, setSuccessMsg] = useState(initialState.successMsg);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: '',
    modalObject: null,
  });

  const [authenticate, setAuthenticate] = useState(authData != null);
  const navigate = useNavigate();

  const dataauth = {
    id: 152,
    name: 'Juan Castillo',
    status: 'ACTIVE',
    tokenInfo: {
      access_token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJyZWFkIiwid3JpdGUiLCJhbGwiXSwiZXhwIjoxNjgzMjI3OTE0LCJqdGkiOiIzMGM4ZTRmOC1hNWIzLTQ0ZWQtYTBiYi0xMGZjNmUyZjIyMDQiLCJjbGllbnRfaWQiOiJmcm9udGVuZGFwcCJ9.jVM6rR4igwoxPvALAEkm23A2RsCM0aCBxiiisWOdJLI',
      expires_in: 86399,
      jti: '30c8e4f8-a5b3-44ed-a0bb-10fc6e2f2204',
      scope: 'read write all',
      token_type: 'bearer',
    },
    userRole: {
      adminLogin: true,
      clientLogin: true,
      department: 'ALL',
      description: 'Rol de Administrador',
      id: 14,
      roleName: 'Administrador',
      authorityRoles: [
        {
          authority: {
            code: 'DOCUMENTS:WRITE',
            path: '/documents',
          },
        },
        {
          authority: {
            code: 'DOCUMENTSROLE:WRITE',
            path: '/documentsRole',
          },
        },
      ],
    },
    username: 'juan.castillo@bitcode-enterprise.com',
  };

  const loginProvider = async (loginData: any) => {
    try {
      //save login data in storage
      const authData = await setAuthData('authPRAdminInfo', loginData);
      console.log(authData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        setAuthenticate(valid);
        setAuthInfo(authData);
        navigate('/', { replace: true });
        return;
      }
    } catch (error) {
      console.log(error);
    }
    //setAuthenticate(false);
    setAuthenticate(true);
  };
// 
  // useLayoutEffect(() => {
  //   //@ts-ignore
  //   loginProvider(dataauth);
  // }, []);

  const logoutProvider = async (e: any) => {
    e.preventDefault();
    await setAuthData('authPRAdminInfo', null);
    setAuthenticate(false);
    //@ts-ignore
    window.location.replace(UrlBase.app_login_base_url);
  };

  /**
   * resetErrorMsg
   */
  const resetErrorMsg = () => {
    setErrorMsg('');
  };

  /**
   * resetSuccessMsg
   */
  const resetSuccessMsg = () => {
    setSuccessMsg('');
  };

  const formContextValues: requestTypes = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      setLoading,
      errorMsg,
      setErrorMsg,
      successMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      loginProvider,
      logoutProvider,
      resetErrorMsg,
      resetSuccessMsg,
    }),
    // eslint-disable-next-line
    [authInfo, authenticate, isLoading, errorMsg, successMsg, modalData]
  );

  return <AppContext.Provider value={formContextValues}>{children}</AppContext.Provider>;
};

export { AppProvider };

export default AppContext;
